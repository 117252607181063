import SEO from '../../components/SEO'
import Layout from '../../layouts/Layout'
import {CommonLayoutProps, GetLayout, ProductOverviewProps} from '../page-props'

export const getProductOverviewLayout: GetLayout<ProductOverviewProps & CommonLayoutProps> = (
  page,
  props,
  translatedPaths,
) => {
  return (
    <SEO name="products" story={props.metaTags} translatedPaths={translatedPaths}>
      <Layout {...props} translatedPaths={translatedPaths}>
        {page}
      </Layout>
    </SEO>
  )
}
