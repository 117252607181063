import SEO from '../../components/SEO'
import ContentContainer from '../../components/containers/ContentContainer'
import Layout from '../../layouts/Layout'
import {CommonLayoutProps, GetLayout, ProductDetailProps} from '../page-props'

export const getProductDetailLayout: GetLayout<ProductDetailProps & CommonLayoutProps> = (
  page,
  props,
  translatedPaths,
) => {
  return (
    <SEO translatedPaths={translatedPaths} noIndex>
      <Layout {...props} translatedPaths={translatedPaths}>
        <ContentContainer roundedTopDesktop className="mt-6">
          {page}
        </ContentContainer>
      </Layout>
    </SEO>
  )
}
