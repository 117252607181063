import {useTranslation} from 'next-i18next'
import Link from 'next/link'
import {useRouter} from 'next/router'
import {useRef} from 'react'
import {useInViewport} from 'react-in-viewport'
import useUser from '../../../lib/hooks/use-user'
import {pathTranslations} from '../../../path-translations'
import ComponentContainer from '../../containers/ComponentContainer'
import SearchBar from '../../form/SearchBar'
import styles from './Hero.module.css'
import classnames from 'classnames'

export interface HeroProps {
  title: string
  subTitle?: string
  description?: string
  backLink?: string
  size?: 'medium' | 'large' | 'fullscreen' | 'full'
  search?: boolean
  customSearchComponent?: (inViewPort: boolean) => React.ReactElement
}

const Hero: React.FC<HeroProps> = ({
  title,
  subTitle,
  description,
  backLink,
  size,
  search,
  customSearchComponent,
  children,
}) => {
  const myRef = useRef<HTMLDivElement>(null)

  const {inViewport} = useInViewport(myRef)

  const router = useRouter()
  const {t} = useTranslation('common')

  // Custom greeting on homepage for logged in users
  const {asPath} = useRouter()
  const {loggedIn, user} = useUser()

  const heroTitle =
    (/^\/(home)?$/.test(asPath) || /^\/(profile-homepage)/.test(asPath)) && loggedIn
      ? `${t('hero.greeting')} ${user.firstName}`
      : title

  const wrapperClasses = classnames('hero g1680:pb-[0.7vw] bg-fixmerBlue g1680:pt-[0vw]', {
    'g768:rounded-t-2xl pb-6  g1024:bg-fixmerBackgroundGrey g1024:text-fixmerBlue': !search,
    'g1024:rounded-t-2xl pb-6  g1024:bg-fixmerBackgroundGrey g1024:text-fixmerBlue': !search,
    'g768:bg-fixmerBlue g768:pb-12 pb-4 g768:text-fixmerWhite': search,
  })

  return (
    <div className={wrapperClasses}>
      <ComponentContainer type={size ?? 'medium'} noPadding>
        <div
          className={`flex mt-8 h-full flex-col ${
            search ? 'g768:items-center g768:mt-0' : 'g1200:mt-[3vw]'
          }`}
        >
          {backLink ? (
            <Link href={backLink}>
              <div className="flex items-center">
                <div
                  className={`mr-2 h-6 w-6 rotate-180 bg-contain bg-center bg-no-repeat g768:brightness-0 ${styles.backIcon}`}
                />
                <p className="font-bold"> {t('general.back')}</p>
              </div>
            </Link>
          ) : null}
          <div>
            <h1
              ref={myRef}
              className={`${!search ? 'mt-8 flex w-fit g1024:text-fixmerBlue' : 'my-8'}`}
            >
              {heroTitle}
            </h1>
          </div>
        </div>
      </ComponentContainer>
      {search ? (
        <>
          <div
            className={`mb-8 flex w-full justify-center bg-fixmerBlue ${
              !inViewport
                ? `fixed top-0 z-20 pt-8 pb-6 transition-all duration-300 ease-in-out ${
                    loggedIn ? 'g1024:top-[5vw] ' : 'g1024:top-[2.5vw]'
                  } `
                : ''
            }`}
          >
            <ComponentContainer type="medium" noPadding className="flex justify-center">
              <div className={`w-full g1024:w-[40vw] g1024:min-w-[30rem]`}>
                {customSearchComponent ? (
                  customSearchComponent(inViewport)
                ) : (
                  <SearchBar
                    inViewPort={inViewport}
                    placeholder={t('navigation.search')}
                    onSearch={query => {
                      router.push(`${pathTranslations.products[router.locale!]}?query=${query}`)
                    }}
                  />
                )}
              </div>
            </ComponentContainer>
          </div>
          <div
            className={
              inViewport ? 'hidden' : 'h-14 g1680:h-[3.5vw] 2xl:h-[2.5vw] g1200:h-[4vw] mb-8'
            }
          ></div>
        </>
      ) : null}

      {children ? (
        <div className="flex flex-col items-center justify-center gap-4">{children}</div>
      ) : null}

      {description || subTitle ? (
        <ComponentContainer type={size ?? 'medium'} className="my-4" noPadding>
          <div
            className={` ${
              search ? 'm-auto  g768:justify-center g768:items-center' : ''
            }  w-full flex-col flex`}
          >
            <p
              className={`h3-like g768:h3-like text-fixmerWhite g1024:text-fixmerBlue mt-4 mb-4 ${
                !search ? '' : 'g1200:mb-[1vw] g1200:mt-[2vw]'
              }`}
            >
              {subTitle}
            </p>
            <p
              className={`text-fixmerWhite g1024:text-fixmerBlue ${
                search
                  ? 'max-w-[60rem] g768:text-center g768:w-[40vw] g768:min-w-[30rem]'
                  : 'max-w-[70vw] form-like'
              }`}
            >
              {description}
            </p>
          </div>
        </ComponentContainer>
      ) : null}
    </div>
  )
}

export default Hero
