import {useTranslation} from 'next-i18next'
import Head from 'next/head'
import {useRouter} from 'next/router'
import type {TranslatedPath} from '../lib/page-props'

export interface StorySEOProps {
  meta_title: string
  meta_description: string
  meta_image: {
    filename: string
  }
  og_title: string
  og_description: string
  og_image: {
    filename: string
  }
}

export type SEOProps = {
  story?: StorySEOProps
  name?: string
  translatedPaths?: TranslatedPath[]
  noIndex?: boolean
  children?: React.ReactNode
}

const SEO = ({story, name, translatedPaths, noIndex, children}: SEOProps) => {
  const router = useRouter()

  const {t} = useTranslation()

  const pathCurrentLanguage = translatedPaths?.find(
    translation => translation.lang === process.env.NEXT_PUBLIC_DEFAULT_LOCALE!,
  )?.path

  let title = ''

  if (name === 'Profile Homepage') {
    title = 'Homepage'
  } else if (name === 'products') {
    title = t('title.products')
  } else if (name) {
    title = name
  }

  return (
    <>
      <Head>
        <title>{`${title} | Fixmer`}</title>
        <link
          rel="canonical"
          href={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${router.locale}${
            router.asPath.split('?')[0]
          }`}
        />
        <meta
          property="og:url"
          content={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${router.locale}${
            router.asPath.split('?')[0]
          }`}
        />
        <meta property="og:type" content="website" />
        {story?.og_title && story?.og_title !== '' ? (
          <meta property="og:title" content={story.og_title} />
        ) : null}
        {story?.og_description && story?.og_description !== '' ? (
          <meta property="og:description" content={story.og_description} />
        ) : null}
        {story?.og_image?.filename && story?.og_image?.filename !== '' ? (
          <meta property="og:image" content={story.og_image.filename} />
        ) : null}
        {story?.meta_title && story?.meta_title !== '' ? (
          <meta property="title" content={`${story?.meta_title} | Fixmer`} />
        ) : null}
        {story?.meta_description && story?.meta_description !== '' ? (
          <meta property="description" content={story?.meta_description} />
        ) : null}
        {story?.meta_image && story?.meta_image.filename !== '' ? (
          <meta property="image" content={story?.meta_image.filename} />
        ) : null}
        {translatedPaths && translatedPaths.length > 0 ? (
          <>
            {translatedPaths.map(translation => (
              <link
                key={translation.lang}
                href={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${translation.lang}${translation.path}`}
                rel="alternate"
                hrefLang={translation.lang}
              />
            ))}
            <link
              href={`${process.env.NEXT_PUBLIC_EXTERNAL_DATA_URL}/${process.env.NEXT_PUBLIC_DEFAULT_LOCALE}${pathCurrentLanguage}`}
              rel="alternate"
              hrefLang="x-default"
            />
          </>
        ) : null}
        {noIndex ? <meta name="robots" content="noindex,nofollow" /> : null}
      </Head>
      {children}
    </>
  )
}

export default SEO
