import {useCombobox} from 'downshift'
import Link from 'next/link'
import type {KeyboardEventHandler} from 'react'
import {useEffect, useState} from 'react'
import type {BasicDoc} from 'react-instantsearch-core'
import {useQuery} from 'react-query'
import styles from './SearchBar.module.css'
import algoliasearch from 'algoliasearch'

export interface SearchBar {
  placeholder: string
  value?: string
  onChange?: (query: string) => void
  inViewPort?: boolean
  onSearch?: (query: string) => void
}

const MAX_SUGGESTIONS = 5

const algoliaClient = algoliasearch(
  process.env.NEXT_PUBLIC_ALGOLIA_APP_ID!,
  process.env.NEXT_PUBLIC_ALGOLIA_QUERY_SUGGESTIONS_API_KEY!,
)

const querySuggestionsIndex = algoliaClient.initIndex(
  process.env.NEXT_PUBLIC_ALGOLIA_INDEX_QUERY_SUGGESTIONS!,
)

const SearchBar = ({placeholder, value, onChange, inViewPort, onSearch}: SearchBar) => {
  const [inputValue, setInputValue] = useState<string>(value ?? '')

  const handleChange = (value: string) => {
    setInputValue(value)
    onChange?.(value)
  }

  const handleSelectSuggestion = (value: string) => {
    setInputValue(value)
    onChange?.(value)

    handleSubmit(value)
  }

  const handleSubmit = (value?: string) => {
    if (value && value.length > 0) {
      onSearch?.(value)
    }
  }

  const handleKeyUp: KeyboardEventHandler<HTMLInputElement> = event => {
    if (event.key === 'Enter') {
      handleSubmit(event.currentTarget.value)
    }
  }

  // Query suggestions query
  const {data: querySuggestions, refetch} = useQuery({
    queryKey: ['querysuggestions'],
    queryFn: async () => {
      const response = await querySuggestionsIndex!.search<BasicDoc>(inputValue, {
        hitsPerPage: MAX_SUGGESTIONS,
      })

      return response.hits
    },
    enabled: !!querySuggestionsIndex,
  })

  // Debounce query suggestions fetch
  useEffect(() => {
    const handler = setTimeout(() => {
      if (!!inputValue) {
        refetch()
      }
    }, 250)

    return () => {
      clearTimeout(handler)
    }
  }, [inputValue, refetch])

  // Combobox (downshift)
  const {isOpen, highlightedIndex, getInputProps, getMenuProps, getItemProps} = useCombobox({
    inputValue,
    items: querySuggestions ?? [],
    itemToString: item => {
      return item?.query ?? ''
    },
    onInputValueChange: ({inputValue}) => {
      handleChange(inputValue ?? '')
    },
    onSelectedItemChange: ({inputValue}) => {
      handleSelectSuggestion(inputValue ?? '')
    },
  })

  const regex = new RegExp(inputValue, 'gi')

  return (
    <div className="w-full 2xl: g1400:w-full g1680:w-[80%] m-auto relative">
      <input
        className={`w-full ${
          inViewPort
            ? 'h-14 g1680:h-[3.5vw] 2xl:h-[2.5vw] g1200:h-[4vw]'
            : 'h-10 g1200:h-12 2xl:h-16'
        } form-like bg-fixmerWhite text-fixmerBlue py-4 px-14 g1200:px-[4vw] rounded-full placeholder-fixmerBlue border-0 caret-brachotRed outline-0`}
        placeholder={placeholder}
        autoComplete="off"
        onKeyUp={handleKeyUp}
        {...getInputProps({
          id: 'search',
          'aria-label': 'search',
          'aria-controls': 'querysuggestions',
        })}
      />
      <i
        className={`bg-contain mr-4 absolute g768:top-[35%] h-full g768:min-w-[1.2rem] g768:min-h-[1.2rem] left-4 g1200:left-[1.5vw]  w-6 top-[0.05rem] g768:h-[1vw] bg-no-repeat bg-center cursor-pointer ${styles.search}`}
        onClick={() => handleSubmit(inputValue)}
      />
      {inputValue !== '' ? (
        <div className="h-full right-12 top-0 g768:right-6 flex items-center justify-center absolute">
          <div
            onClick={() => handleChange('')}
            className={`w-6 cursor-pointer hover:scale-125 transition-transform transition-300 ease-in-out bg-no-repeat h-6 bg-center right-12  bg-contain ${styles.cross}`}
          ></div>
        </div>
      ) : null}
      <Link
        href="/scan"
        className={`bg-contain absolute g768:hidden right-4 top-4 g768:top-[35%] w-6 h-6 bg-no-repeat bg-center ${
          inViewPort ? 'g768:top-[35%]' : 'top-[0.5rem]'
        } ${styles.scan}`}
        aria-label="scan"
      />

      <ul
        className={`absolute h-fit z-50 w-full ${
          !(isOpen && querySuggestions?.length) ?? 'hidden'
        }`}
        {...getMenuProps({
          id: 'querysuggestions',
          'aria-labelledby': 'search',
        })}
      >
        {isOpen && querySuggestions?.length ? (
          <div className="relative w-11/12 -top-2 bg-white mx-auto rounded-2xl shadow-[0px_0px_8px_rgba(88,88,88,0.4)] py-5">
            {querySuggestions?.map((hit, index) => {
              return (
                <li
                  key={hit.objectID}
                  value={hit.query}
                  className={`block text-form text-black px-8 py-1 ${
                    highlightedIndex === index ? 'bg-fixmerLightGrey' : ''
                  }`}
                  dangerouslySetInnerHTML={{
                    __html: hit.query.replace(regex, match => `<b>${match}</b>`),
                  }}
                  role="option"
                  aria-selected="true"
                  {...getItemProps({item: hit, index})}
                />
              )
            })}
          </div>
        ) : null}
      </ul>
    </div>
  )
}

export default SearchBar
