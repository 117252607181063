import SEO from '../../components/SEO'
import ContentContainer from '../../components/containers/ContentContainer'
import Hero from '../../components/sections/Hero'
import Layout from '../../layouts/Layout'
import {CommonLayoutProps, GetLayout, StoryPageProps} from '../page-props'

export const getStoryPageLayout: GetLayout<StoryPageProps & CommonLayoutProps> = (
  page,
  props,
  translatedPaths,
) => {
  return (
    <SEO name={props.story.name} story={props.story.content} translatedPaths={translatedPaths}>
      <Layout {...props} translatedPaths={translatedPaths}>
        {props.story.content.title ? (
          <Hero
            search={props.story.content.searchbar}
            description={props.story.content.description}
            subTitle={props.story.content.subtitle}
            title={props.story.content.title}
            size={props.story.content.hero_size}
          />
        ) : null}
        <ContentContainer
          className={!props.story.content.title ? 'mt-8 g768:mt-0' : ''}
          roundedTopDesktop={props.story.content.searchbar}
        >
          {page}
        </ContentContainer>
      </Layout>
    </SEO>
  )
}
